<template>
  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
    <loader v-if="!isContentLoaded" :is-visible="!isContentLoaded"></loader>
    <div v-if="isContentLoaded">
      <div class="iq-card-header d-flex justify-content-between">
        <apex-chart-header :disable-range-selection="true" :header="header" :header-icon="headerIcon"></apex-chart-header>
      </div>
      <div class="card-body iq-card-body p-1">
        <div id="apexBarChart">
          <apexchart type="bar" v-if="isContentLoaded" :options="chartOptions" :series="series" :colors="colors"></apexchart>
        </div>
      </div>
    </div>
  </iq-card>
</template>

<script>
  import Loader from "../../components/shared/Loader";
  import ApexChartHeader from "../../components/apexCharts/ApexChartHeader";

  import ChartService from "../../services/chart";
  import "../../plugins/apexchart";

    export default {
        components: {
          Loader,
          ApexChartHeader
        },
        props: {
            dataUrl: String,
            dateMin: String,
            dateMax: String,
            stacked: Boolean,
            dataObject: Object
        },
        data() {
            return {
                date: { startDate: '', endDate: '' },
                isCurrency: false,
                publicYaxisValue: '',
                isContentLoaded: false,
                header: '',
                headerIcon: "",
                series: [],
                colors: [],
                chartOptions: {
                    chart: {
                        type: 'bar',
                        stacked: this.stacked,
                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true,
                            }
                        },
                        defaultLocale: 'tr',
                        locales: [{
                            name: 'tr',
                            options: {
                                toolbar: {
                                    "exportToSVG": this.$t('DownloadSvg'),
                                    "exportToPNG": this.$t('DownloadPng'),
                                    "menu": this.$t('Menu'),
                                    "selection": this.$t('Selection'),
                                    "selectionZoom": this.$t('SelectionZoom'),
                                    "zoomIn": this.$t('ZoomIn'),
                                    "zoomOut": this.$t('zoomOut'),
                                    "pan": this.$t('Panning'),
                                    "reset": this.$t('ResetZoom')
                                }
                            }
                        }]
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ["#089bab", "#fca162"],
                    xaxis: {
                    },
                    yaxis: {
                        labels: {
                            formatter: (value) => {

                                let val = value;

                                if (this.isCurrency && typeof value === 'number') {

                                    const parts = value.toString().split(".");
                                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    val = parts.join(".");
                                }

                                if (this.publicYaxisValue != '' && this.publicYaxisValue != null) {
                                    val += ' ' + this.publicYaxisValue;
                                }


                                return val;     

                            },
                        },
                    }

                }
            }
        },
        methods: {
            getData() {
                ChartService.getChartData(this.computedUrl)
                    .then(response => {
                        if (response) {
                            this.setData(response);
                        }
                    });
            },
            setData(filledChartData) {
                let $this = this;
                $this.series = filledChartData.series;
                $this.header = $this.$t(filledChartData.chartHeader);
                $this.headerIcon = filledChartData.chartHeaderIcon ?? "";
                $this.isCurrency = filledChartData.isCurrency;
                $this.publicYaxisValue = filledChartData.publicYaxisValue ?? "";

                if (filledChartData.colors) {
                    $this.chartOptions.colors = filledChartData.colors;
                } 

                if (filledChartData.currencySymbol) {
                    $this.header += " (" + filledChartData.currencySymbol + ")"
                }

                $this.chartOptions.xaxis = filledChartData.xaxis;

                $this.isContentLoaded = true;
            }
        },
        watch: {
            date: function () {
                this.getData()
            },
            computedUrl: {
                handler: function (value) {
                    if (value)
                        this.getData();
                },
                immediate: true
            },
            chartData: {
                handler: function () {
                    if (this.chartData)
                        this.setData(this.chartData);
                },
                immediate: true
            }
        },
        computed: {
            computedUrl: function () {
                let $this = this;
                if ($this.dateMin && $this.dateMax) {
                    return $this.dataUrl + "?d1=" + $this.dateMin + "&d2=" + $this.dateMax;
                }
                else {
                    return $this.dataUrl;
                }
            },
            chartData: function () {
                return this.dataObject;
            }
        },
        mounted: function () {
        }
    }
</script>
