<template>
  <b-container fluid>
    <b-row>
      <simple-box v-if="$store.getters['Auth/checkPermission']('dashboard_appointmentsoftoday')" data-url="/chart/GetAppointmentsCount" bg-color="warning"></simple-box>
      <simple-box v-if="$store.getters['Auth/checkPermission']('dashboard_accountingsummaryoftoday')" data-url="/chart/GetAccountingSummaryTotal" box-icon="fa-piggy-bank"></simple-box>
    </b-row>

    <b-row>
      <b-col v-if="$store.getters['Auth/checkPermission']('dashboard_bubblechart')" md="6" xl="8">
        <apex-bubble-chart :data-object="dashboardCharts.bubbleChart"></apex-bubble-chart>
      </b-col>
      <b-col v-if="$store.getters['Auth/checkPermission']('dashboard_appointmentsbarchartbyentity')" md="6" xl="4">
        <apex-bar-chart :data-object="dashboardCharts.appointmentsBarChartByEntity"></apex-bar-chart>
      </b-col>
      <b-col v-if="$store.getters['Auth/checkPermission']('dashboard_customerpiechartbytype')" md="6" xl="4">
        <apex-pie-chart :data-object="dashboardCharts.customerPieChartByType"></apex-pie-chart>
      </b-col>
      <b-col v-if="$store.getters['Auth/checkPermission']('dashboard_appointmentcountsgrouppedbydays')" md="6" xl="8">
        <apex-area-chart :data-object="dashboardCharts.appointmentCountsGrouppedByDays"></apex-area-chart>
      </b-col>
      <b-col v-if="$store.getters['Auth/checkPermission']('dashboard_areabillandaccountingchart')" md="6" xl="8">
        <apex-area-chart :data-object="dashboardCharts.totalAmountGrouppedByBillAndAccounting"></apex-area-chart>
      </b-col>
      <b-col v-if="$store.getters['Auth/checkPermission']('dashboard_billdetailsbarchartbyentity')" md="6" xl="4">
        <apex-bar-chart :data-object="dashboardCharts.billDetailsBarChartByEntity"></apex-bar-chart>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import simpleBox from '../../../components/apexCharts/SimpleBox';
  import apexBubbleChart from '../../../components/apexCharts/ApexBubbleChart';
  import apexPieChart from '../../../components/apexCharts/ApexPieChart';
  import apexBarChart from '../../../components/apexCharts/ApexBarChart';
  import apexAreaChart from '../../../components/apexCharts/ApexAreaChart';

  import chartService from '../../../services/chart';

export default {
    name: 'Dashboard',
    components: {
      simpleBox,
      apexBubbleChart,
      apexPieChart,
      apexBarChart,
      apexAreaChart
    },
    data() {
      return {
        dashboardCharts: {}
      }
    },
    methods: {
      getAllChartsInTheDashboard() {
        chartService.getAllChartsInTheDashboard()
          .then(response => {
            this.dashboardCharts = response;
          });
      }
    },
    mounted: function () {
      this.getAllChartsInTheDashboard();
    },
}
</script>
